<template>
  <div style="padding: 20px;width: 100%">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>远程监控</el-breadcrumb-item>
      <el-breadcrumb-item>视频监控</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <a-row :gutter="1">
        <a-col :span="19">
          <div v-if="showCamera">
            <div id="video-container" :style="{width: '100%',backgroundColor: '#02070d',height: screenHeight+'px'}"
                 ref="aColRef"></div>
          </div>
        </a-col>
        <a-col :span="5">
          <div :style="{width: '100%',backgroundColor: '#3f3f3f',height: screenHeight+'px'}">
            <div style="border-bottom: 1px solid hsla(0,0%,100%,.1)" v-for="item in cameraDriveData" :key="item.id">
              <a href="#">
                <span style="padding: .6rem 1rem;display: block;color: #cecece" @click="change(item)">{{item.cameraName+'#'+item.informationId}}</span>
              </a>
            </div>
            <div :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                textAlign: 'left',
                zIndex: 1,
              }">
              <div style="height: 30px">
                <a-tooltip placement="top" title="打开摄像头声音">
                  <a href="#" @click="openSound">
                    <img src="../../img/camera/声音.png" style="height: 80%"/>
                  </a>
                </a-tooltip>
                <a-tooltip placement="top" title="关闭摄像头声音">
                  <a href="#" @click="closeSound">
                    <img src="../../img/camera/静音.png" style="height: 80%;margin-left: 20px"/>
                  </a>
                </a-tooltip>
                <a-tooltip placement="top" title="重新连接摄像头">
                  <a href="#" @click="change">
                    <img src="../../img/camera/执行中断.png" style="height: 80%;margin-left: 20px"/>
                  </a>
                </a-tooltip>
              </div>
            </div>

          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
    import EZUIKit from "ezuikit-js";

    export default {
        name: "Camera",
        data() {
            return {
                token:null,
                url:'',
                cameraDriveData: [],
                player: null,
                showCamera: true,
                screenWidth: document.documentElement.clientWidth,
                //屏幕高度
                screenHeight: document.documentElement.clientHeight - 120,
            }
        },
        props: {
            msg: String
        },
        watch: {
            'screenWidth': function (val) { //监听屏幕宽度变化
                console.log(val);
                this.reload();
                //this.change();
            },
            'screenHeight': function (val) { //监听屏幕高度变化
                console.log(val);
            },
        },

        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 120; //窗口高度
            };
            /*mounted 节点获取的页面宽度有问题  在点击浏览器刷新的时候*/
            /*console.log("mounted---" + document.documentElement.clientWidth);
            console.log("mounted---" + document.documentElement.clientHeight);*/
        },
        created() {
            this.initCameraDriveMsg();
            // console.log("data---" + this.screenWidth);
            // console.log("created---" + document.documentElement.clientWidth);
            // console.log("created---" + document.documentElement.clientHeight);
        },
        methods: {
            async initCameraDriveMsg() {
                const {data: res} = await this.http.camera.getCameraToken();
                if (res.status === 200) {
                    this.token = res.data.accessToken;
                    this.http.camera.getCameraDriveList().then(response => {
                        if (response.data.status === 200) {
                            this.cameraDriveData = response.data.data;
                            if (this.cameraDriveData.length > 0) {
                                this.url = "ezopen://DIANAY@open.ys7.com/" + this.cameraDriveData[0].cameraNetworkDvrSerialNumber + "/" + this.cameraDriveData[0].informationId + ".live";
                                this.init();
                            }
                        } else {
                            this.$message.warning('加载摄像头数据失败');
                        }
                    });
                } else {
                    this.$message.error("获取摄像头token失败");
                }
            },
            init() {
                setTimeout(() => {
                    let cameraWidth = this.$refs.aColRef.clientWidth;
                    let url = this.url;
                    let token = this.token;
                    this.$nextTick(() => {
                        this.player = new EZUIKit.EZUIKitPlayer({
                            autoplay: true,
                            id: "video-container",
                            accessToken: token,
                            url: url,
                            template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                            audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                            width: cameraWidth,
                            height: this.screenHeight,
                        });
                    });
                }, 100)
            },
            reload() {
                this.player.stop();
                this.showCamera = false;
                this.$nextTick(() => {
                    this.showCamera = true;
                });
                this.init();
            },
            change(item) {
                this.player.stop();
                // 切换为直播
                setTimeout(() => {
                    let url = "ezopen://DIANAY@open.ys7.com/" + item.cameraNetworkDvrSerialNumber + "/" + item.informationId + ".live";
                    this.player.play({
                        url: url
                    })
                }, 500);
                this.url = "ezopen://DIANAY@open.ys7.com/" + item.cameraNetworkDvrSerialNumber + "/" + item.informationId + ".live";
            },
            openSound() {
                this.player.openSound();
                this.$message.success('打开摄像头声音成功!');
            },
            closeSound() {
                this.player.closeSound();
                this.$message.success('关闭摄像头声音成功!');
            },
        }
    };
</script>

<style>
  .camera_class {

  }
</style>
