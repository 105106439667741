var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px","width":"100%"}},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/welcome' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("远程监控")]),_c('el-breadcrumb-item',[_vm._v("视频监控")])],1),_c('div',[_c('a-row',{attrs:{"gutter":1}},[_c('a-col',{attrs:{"span":19}},[(_vm.showCamera)?_c('div',[_c('div',{ref:"aColRef",style:({width: '100%',backgroundColor: '#02070d',height: _vm.screenHeight+'px'}),attrs:{"id":"video-container"}})]):_vm._e()]),_c('a-col',{attrs:{"span":5}},[_c('div',{style:({width: '100%',backgroundColor: '#3f3f3f',height: _vm.screenHeight+'px'})},[_vm._l((_vm.cameraDriveData),function(item){return _c('div',{key:item.id,staticStyle:{"border-bottom":"1px solid hsla(0,0%,100%,.1)"}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticStyle:{"padding":".6rem 1rem","display":"block","color":"#cecece"},on:{"click":function($event){return _vm.change(item)}}},[_vm._v(_vm._s(item.cameraName+'#'+item.informationId))])])])}),_c('div',{style:({
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              textAlign: 'left',
              zIndex: 1,
            })},[_c('div',{staticStyle:{"height":"30px"}},[_c('a-tooltip',{attrs:{"placement":"top","title":"打开摄像头声音"}},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.openSound}},[_c('img',{staticStyle:{"height":"80%"},attrs:{"src":require("../../img/camera/声音.png")}})])]),_c('a-tooltip',{attrs:{"placement":"top","title":"关闭摄像头声音"}},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.closeSound}},[_c('img',{staticStyle:{"height":"80%","margin-left":"20px"},attrs:{"src":require("../../img/camera/静音.png")}})])]),_c('a-tooltip',{attrs:{"placement":"top","title":"重新连接摄像头"}},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.change}},[_c('img',{staticStyle:{"height":"80%","margin-left":"20px"},attrs:{"src":require("../../img/camera/执行中断.png")}})])])],1)])],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }